import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useContext, useRef, useState } from "react";

// assets
import { SvgNewHomeLogo } from "assets/icons/js/SvgNewHomeLogo";
import { SvgSearch } from "assets/icons/js/SvgSearch";
import { NavbarDropdown } from "components/NavbarDropdown";
import { NavbarSearchBar } from "components/NavbarSearchBar";
import { SvgMenu as Menu } from "assets/icons/js/SvgMenu";

// customHooks
import { useUser } from "customHooks/useUser";
import { useMediaQuery } from "customHooks/useMediaQuery";
import { useOnClickOutside } from "customHooks/useOnClickOutside";

// store
import { UserContext } from "store";

// style
import "./style.scss";
import { SearchBarData } from "context/SearchBarData";
import { SvgPhone } from "assets/icons/js/SvgPhone";
import { SvgHomeLogo } from "assets/icons/js/SvgHomeLogo";
import { HomeSearchNavbar } from "../HomeSearchNavbar";
import { PhoneSearchFilters } from "components/NewSearch/mobile/PhoneSearchFilters";
import { ReactComponent as SVGPhone } from "assets/phone.svg";
import DeadlineStickyNavbar from "components/DeadlineStickyBar";
import {
	amenities,
	categories,
	collections,
	destinations,
	inspiringIdeas,
} from "./constant/navData";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
export const HomeNavbar = ({
	setShouldShowModal,
	setIsMenuOpen,
	isMenuOpen,
	link,
	isBackgroundTransparent = false,
	isTabletOrMobile,
}: any) => {
	const [showSearchBar, setShowSearchBar] = useState(false);
	const [userDropdown, setUserDropdown] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const dropdownRef = useRef();
	useOnClickOutside(dropdownRef, () => setUserDropdown(false));

	// getting current user data
	const { state } = useContext(UserContext);
	const { currentUser } = state;

	// to check if user exist
	const user = useUser();

	const width = useMediaQuery();
	const { pathname } = useLocation();
	const isSearch = pathname.split("/")[1] === "villas";
	const isUni = pathname.split("/")[1] === "universalurl";
	const isCollection = pathname.split("/")[1] === "collection";
	const isHome = pathname.split("/")[1] === "";
	const isContactUs = pathname.split("/")[1] === "contact-us";
	const isBooking = pathname.split("/")[1] === "booking";
	const isLaptopOrLower = width < 1080 ? true : false;
	const isListingPage = pathname.split("/")[1] === "view";
	// to logout the user
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("uuid");
		window.location.reload();
	};

	const getRedirectLink = () => {
		const token = localStorage.getItem("token");
		const isHomeowner = currentUser.isHomeowner;

		if (token && isHomeowner)
			return `http://host.saffronstays.com/account/dashboard?jwt=${token}`;
		return `https://www.partner.saffronstays.com`;
	};
	const { dispatch } = useContext(UserContext);
	const handleUniCall = () => {
		searchParams.set("get_help", "true");
		searchParams.set("who_should_i_call", "true");
		setSearchParams(searchParams, { replace: true });
	};

	const [isDestination, setIsDestination] = useState(false);
	const [isCollections, setIsCollections] = useState(false);
	const [isCategories, setIsCategories] = useState(false);
	const [isAmenities, setIsAmenities] = useState(false);
	const [isInspiringIdeas, setIsInspiringIdeas] = useState(false);
	return (
		<>
			{showSearchBar && (
				<div
					className="home__navbar__overlay"
					onClick={() => setShowSearchBar(false)}
				></div>
			)}
			<div
				className={
					showSearchBar
						? "home__navbar__container home__navbar__container__active"
						: "home__navbar__container"
				}
				style={{
					// @ts-ignore
					borderBottom:
						!isBackgroundTransparent && !isSearch
							? "1px #dddddd solid"
							: !isBackgroundTransparent && isSearch && isLaptopOrLower
							? ""
							: !isBackgroundTransparent && isSearch && !isLaptopOrLower
							? "1px #dddddd solid"
							: "",
					// @ts-ignore
					position: !isBackgroundTransparent && "relative",
					// @ts-ignore
					background: !isBackgroundTransparent && "#ffffff",
					paddingBottom:
						isSearch || isUni || isCollection || isContactUs ? "10px" : "5px",
				}}
			>
				<DeadlineStickyNavbar />
				<div
					className={
						showSearchBar ? "home__navbar home__navbar__active" : "home__navbar"
					}
					style={{
						paddingBottom:
							isSearch && !isLaptopOrLower
								? "10px"
								: isSearch && isLaptopOrLower
								? ""
								: "10px",
					}}
				>
					<div className="nav__left">
						<Link aria-label="logo" to="/">
							<span className="logo">
								{!isBackgroundTransparent ? (
									<SvgNewHomeLogo
										height={width <= 500 ? 36 : 50}
										width={width <= 500 ? 136 : 195}
									/>
								) : (
									<SvgHomeLogo height={width <= 320 ? 36 : 50} />
								)}
							</span>
						</Link>
					</div>
					{!isTabletOrMobile &&
						!isSearch &&
						!isUni &&
						!isContactUs &&
						!isCollection &&
						!isBooking &&
						!isListingPage && (
							<div className="nav-mid">
								<div
									className="destination"
									onMouseEnter={() => {
										setIsDestination(true);
									}}
									onMouseLeave={() => {
										setIsDestination(false);
									}}
								>
									<p>
										Destinations{" "}
										{isDestination ? (
											<IoMdArrowDropup />
										) : (
											<IoMdArrowDropdown />
										)}
									</p>
									{isDestination && (
										<div className="destination-mapping-wrapper">
											<ul>
												{destinations?.slice(0, 2)?.map((ele, index) => {
													return (
														<div
															key={index}
															className="destination-mapping-header"
														>
															<p>{ele.name}</p>
															<ul>
																{ele?.locations?.map((items) => {
																	return (
																		<li
																			onClick={() => {
																				window.location.href = `/villas/villas-in-${items}?checkin=&checkout=&guests=1&adults=0&children=0&infants=0&rooms=1&pets=0&amenities=&events=&pocket=&same_day=&club=&signature=&breakfast=&radius=1&more=`;
																			}}
																		>
																			{items}
																		</li>
																	);
																})}
															</ul>
														</div>
													);
												})}
											</ul>
											<ul>
												{destinations?.slice(2, 5)?.map((ele, index) => {
													return (
														<div
															key={index}
															className="destination-mapping-header"
														>
															<p>{ele.name}</p>
															<ul>
																{ele?.locations?.map((items) => {
																	return (
																		<li
																			onClick={() => {
																				window.open(
																					`/villas/villas-in-${items}?checkin=&checkout=&guests=1&adults=0&children=0&infants=0&rooms=1&pets=0&amenities=&events=&pocket=&same_day=&club=&signature=&breakfast=&radius=1&more=`,
																					"_blank",
																				);
																			}}
																		>
																			{items}
																		</li>
																	);
																})}
															</ul>
														</div>
													);
												})}
											</ul>
											<ul>
												{destinations?.slice(5, 9)?.map((ele, index) => {
													return (
														<div
															key={index}
															className="destination-mapping-header"
														>
															<p>{ele.name}</p>
															<ul>
																{ele?.locations?.map((items) => {
																	return (
																		<li
																			onClick={() => {
																				window.open(
																					`/villas/villas-in-${items}?checkin=&checkout=&guests=1&adults=0&children=0&infants=0&rooms=1&pets=0&amenities=&events=&pocket=&same_day=&club=&signature=&breakfast=&radius=1&more=`,
																					"_blank",
																				);
																			}}
																		>
																			{items}
																		</li>
																	);
																})}
															</ul>
														</div>
													);
												})}
											</ul>
											<ul>
												{destinations?.slice(9, 11)?.map((ele, index) => {
													return (
														<div
															key={index}
															className="destination-mapping-header"
														>
															<p>{ele.name}</p>
															<ul>
																{ele?.locations?.map((items) => {
																	return (
																		<li
																			onClick={() => {
																				window.open(
																					`/villas/villas-in-${items}?checkin=&checkout=&guests=1&adults=0&children=0&infants=0&rooms=1&pets=0&amenities=&events=&pocket=&same_day=&club=&signature=&breakfast=&radius=1&more=`,
																					"_blank",
																				);
																			}}
																		>
																			{items}
																		</li>
																	);
																})}
															</ul>
														</div>
													);
												})}
											</ul>
											<ul>
												{destinations?.slice(11, 14)?.map((ele, index) => {
													return (
														<div
															key={index}
															className="destination-mapping-header"
														>
															<p>{ele.name}</p>
															<ul>
																{ele?.locations?.map((items) => {
																	return (
																		<li
																			onClick={() => {
																				window.open(
																					`/villas/villas-in-${items}?checkin=&checkout=&guests=1&adults=0&children=0&infants=0&rooms=1&pets=0&amenities=&events=&pocket=&same_day=&club=&signature=&breakfast=&radius=1&more=`,
																					"_blank",
																				);
																			}}
																		>
																			{items}
																		</li>
																	);
																})}
															</ul>
														</div>
													);
												})}
											</ul>
										</div>
									)}
								</div>
								<div
									className="collections"
									onMouseEnter={() => {
										setIsCollections(true);
									}}
									onMouseLeave={() => {
										setIsCollections(false);
									}}
								>
									<p>
										Collections{" "}
										{isCollections ? (
											<IoMdArrowDropup />
										) : (
											<IoMdArrowDropdown />
										)}
									</p>
									{isCollections && (
										<div className="collection-mapping-wrapper">
											{collections?.map((ele, index) => {
												return (
													<div className="collection-mapping-header">
														<p>{ele.name}</p>
														<ul>
															{ele?.items?.map((item, idx) => {
																return (
																	<li
																		onClick={() => {
																			window.location.href = `/${ele.slugs[idx]}`;
																		}}
																	>
																		{item}
																	</li>
																);
															})}
														</ul>
													</div>
												);
											})}
										</div>
									)}
								</div>
								{/* <div
									className="categories"
									onMouseEnter={() => {
										setIsCategories(true);
									}}
									onMouseLeave={() => {
										setIsCategories(false);
									}}
								>
									<p>
										Categories{" "}
										{isCategories ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
									</p>
									{isCategories && (
										<div className="category-mapping-wrapper">
											{categories?.map((ele, index) => {
												return (
													<div className="category-mapping-header">
														<img src={ele?.icon} alt="" />
													</div>
												);
											})}
										</div>
									)}
								</div> */}
								<div
									className="amenities"
									onMouseEnter={() => {
										setIsAmenities(true);
									}}
									onMouseLeave={() => {
										setIsAmenities(false);
									}}
								>
									<p>
										Amenities{" "}
										{isAmenities ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
									</p>
									{isAmenities && (
										<div className="amenity-mapping-wrapper">
											{amenities?.map((ele, index) => {
												return (
													<div
														onClick={() => {
															window.open(ele?.link, "_blank");
														}}
														className="amenity-mapping-header"
													>
														<p>{ele?.title}</p>
													</div>
												);
											})}
										</div>
									)}
								</div>
								{/* <div
									className="amenities"
									onMouseEnter={() => {
										setIsInspiringIdeas(true);
									}}
									onMouseLeave={() => {
										setIsInspiringIdeas(false);
									}}
								>
									<p>
										Inspiring Ideas{" "}
										{isInspiringIdeas ? (
											<IoMdArrowDropup />
										) : (
											<IoMdArrowDropdown />
										)}
									</p>
									{isInspiringIdeas && (
										<div className="amenity-mapping-wrapper">
											{inspiringIdeas?.map((ele, index) => {
												return (
													<div
														onClick={() => {
															window.open(ele?.slug, "_blank");
														}}
														className="amenity-mapping-header"
													>
														<p>{ele?.title}</p>
													</div>
												);
											})}
										</div>
									)}
								</div> */}
							</div>
						)}

					{isSearch && !isLaptopOrLower && (
						<HomeSearchNavbar
							openSearchBar={() => setShowSearchBar(true)}
							showSearchBar={showSearchBar}
						/>
					)}
					{!isTabletOrMobile ? (
						<>
							{pathname.split("/")[1] !== "villas" &&
								!isUni &&
								!isCollection &&
								!isHome && (
									<div
										className={
											showSearchBar
												? "nav__middle search__animate"
												: "nav__middle"
										}
										onClick={() => {
											setShowSearchBar(true);
										}}
									>
										<input
											type="text"
											placeholder="Search for location, villa, facilities..."
										/>
										<div className="search__nav__button">
											<SvgSearch color="#ffffff" />
										</div>
									</div>
								)}
							<div className="nav__right">
								{!isUni && !isCollection ? (
									<>
										<a
											aria-label="Phone Number"
											className="nav__link number"
											href={"tel:08069160000"}
											style={{
												color: !isBackgroundTransparent ? "#333333" : "#000",
												gap: "10px",
											}}
										>
											<SvgPhone
												color={!isBackgroundTransparent ? "#333333" : "#000"}
											/>
											<p>08069160000</p>
										</a>
										<a
											aria-label="HomeOwner"
											className="nav__link home_nav"
											href={getRedirectLink()}
											style={{
												color: !isBackgroundTransparent ? "#333333" : "#000",
												whiteSpace: "nowrap",
											}}
										>
											List your home
										</a>
										{/* <a
											href="/travelpartner"
											aria-label="Travel Agent"
											className="nav__link home_nav"
											style={{
												color: !isBackgroundTransparent ? "#333333" : "#ffffff",
												whiteSpace: "nowrap",
											}}
										>
											Travel Partners
										</a> */}
										{/* <a
											aria-label="HomeOwner"
											className="nav__link home_nav"
											href="https://www.celebrations.saffronstays.com/"
											style={{
												color: !isBackgroundTransparent ? "#333333" : "#ffffff",
												whiteSpace: "nowrap",
											}}
										>
											Book an Event
										</a> */}
									</>
								) : null}
								{!user ? (
									<button
										aria-label="Login"
										className={`navbar-link-button ${
											isBackgroundTransparent && "changeButton"
										}`}
										onClick={() => {
											dispatch({
												type: "FROM_BOOK_BUTTON",
												payload: false,
											});
											setShouldShowModal(true);
										}}
										// style={{
										// 	marginRight: "1.2rem",
										// }}
										title="Log in / Sign up"
									>
										Log in
									</button>
								) : (
									<div
										className="navbar__profile__dropdown"
										// @ts-ignore
										ref={dropdownRef}
									>
										<div
											className="profile__user__detail"
											onClick={() => setUserDropdown(!userDropdown)}
										>
											<span className="user__img">
												<img
													src={`https://api.dicebear.com/7.x/initials/svg?seed=${currentUser.firstName}%7D.svg`}
													alt="#"
												/>
											</span>
										</div>
										{userDropdown && (
											<NavbarDropdown
												handleLogout={handleLogout}
												currentUser={currentUser}
											/>
										)}
									</div>
								)}
							</div>
						</>
					) : (
						<div
							className="menu-icon"
							style={{
								marginRight: "20px",
								cursor: "pointer",
								display: "flex",
								alignItems: "center",
								gap: "14px",
							}}
						>
							{isUni ? (
								<button
									onClick={() => handleUniCall()}
									className="call-us-btn"
									style={{
										background: "transparent",
										outline: "none",
										border: "none",
									}}
								>
									<SVGPhone /> Call Us
								</button>
							) : (
								<a className="call-us-btn" href={"tel:08069160000"}>
									<SVGPhone /> Call Us
								</a>
							)}

							<Menu
								onClick={() => setIsMenuOpen(true)}
								color={!isBackgroundTransparent ? "black" : "black"}
							/>
						</div>
					)}
				</div>
				{isTabletOrMobile &&
					!isSearch &&
					!isUni &&
					!isContactUs &&
					!isCollection &&
					!isHome &&
					!isBooking && (
						<div
							className="nav__middle__phone"
							onClick={() => {
								window.location.href = "/mobile-search";
							}}
						>
							<input type="text" placeholder="Search for place, home..." />
							<SvgSearch color="#999999" />
						</div>
					)}
				{showSearchBar && (
					<SearchBarData>
						<NavbarSearchBar
							showSearchBar={showSearchBar}
							closeSearchBar={() => setShowSearchBar(false)}
						/>
					</SearchBarData>
				)}
			</div>
			{isSearch && isLaptopOrLower && (
				<div className="new__search__phone__container">
					<HomeSearchNavbar
						openSearchBar={() => setShowSearchBar(true)}
						showSearchBar={showSearchBar}
					/>
					<PhoneSearchFilters />
				</div>
			)}
		</>
	);
};
