import React from "react";
import "./style.scss";
import SvgBrand from "../assets/SvgBrand";
import { BiLogoPinterestAlt } from "react-icons/bi";
import { SvgFooterFacebook } from "assets/icons/js/SvgFooterFacebook";
import { SvgFooterInsta } from "assets/icons/js/SvgFooterInsta";
import { SvgFooterLinkdin } from "assets/icons/js/SvgFooterLinkdin";
import { SvgFooterTwitter } from "assets/icons/js/SvgFooterTwitter";
import { SvgFooterYoutube } from "assets/icons/js/SvgFooterYoutube";
import clevertap from "clevertap-web-sdk";
const FooterMobile = () => {
	const menuColumns = [
		{
			column: "About Us",
			links: ["Our Story", "Our Blog", "Club Infinitum", "Our Partners"],
			slugs: ["/about-us", "/blog", "/club-infinitum", "/partners"],
		},
		{
			column: "Work With Us",
			links: [
				"List your home",
				"Travel Partners",
				"For Homeowners",
				"For Caretakers",
				"Join our team",
			],
			slugs: [
				"https://partner.saffronstays.com/",
				"https://saffronstays.com/travelpartner",
				"https://linktr.ee/hostconnect",
				"https://linktr.ee/caretakerapp",
				"/join-us",
			],
		},
		{
			column: "Information",
			links: [
				"Cancellation Policy",
				"Rescheduling Policy",
				"Other Policies",
				"Sitemap",
				"Contact Us",
			],
			slugs: [
				"/cancellation-policy",
				"/reschedule-policy",
				"/terms-service",
				"/sitemap",
				"/contact-us",
			],
		},
		{
			column: "Villa & Homestays",
			links: [
				"Villas in Lonavala",
				"Villas in Karjat",
				"Villas in Alibaug",
				"Villas in Kamshet",
				"Villas in Mahabaleshwar",
				"Villas in Panchgani",
				"Villas in Palghar",
				"Villas in Nashik",
				"Homestays in Manali",
				"Homestays in Shimla",
				"Homestays in Himachal",
				"Homestays in Uttarakhand",
				"Homestays in Nainital",
				"Homestays in Rajasthan",
				"Homestays in Karnataka",
				"Homestays in Kerala",
				"Homestays in Delhi",
			],
			slugs: [
				"/villas/villas-in-lonavala",
				"/villas/villas-in-karjat",
				"/villas/villas-in-alibaug",
				"/villas/villas-in-kamshet",
				"/villas/villas-in-mahabaleshwar",
				"/villas/villas-in-panchgani",
				"/villas/villas-in-palghar",
				"/villas/villas-in-nashik",
				"/villas/villas-in-manali",
				"/villas/villas-in-shimla",
				"/villas/villas-in-himachal",
				"/homestays/homestays-in-uttarakhand",
				"/homestays/homestays-in-nainital",
				"/homestays/homestays-in-rajasthan",
				"/homestays/homestays-in-karnataka",
				"/homestays/homestays-in-kerala",
				"/homestays/homestays-in-delhi",
			],
		},
		{
			column: "Collections",
			links: [
				"Surprise Me",
				"Newly Launched Villas",
				"Award Winning Villas",
				"Celebrity Choice Villas",
				"Pure Veg Homes",
				"Private Pool Villas",
				"Homes with Jacuzzi",
				"Pocket-Friendly Villas",
				"Stunning View Villas",
				"Top Lake View Villas",
				"Unique Hillside Villas",
				"Top Beachside Villas",
			],
			slugs: [
				"/collection/hidden-gems",
				"/collection/newly-launched-villas",
				"/collection/award-winning-best-villas",
				"/collection/celebrity-choice-perfect-for-instagram-villa",
				"/collection/veg-only-homes",
				"/collection/veg-only-homes",
				"/collection/top-private-pool-villas",
				"/villas/villas-near-me?amenities=75&events=&more=",
				"/collection/budget-villas",
				"/collection/best-impeccable-view-villas",
				"/collection/best-lake-view-villa",
				"/collection/family-getaway-to-the-hills",
				"/collection/best-villas-by-the-beach-for-your-next-famjam",
			],
		},
		{
			column: "For Guests",
			links: [
				"Villas for Birthdays",
				"Villas for Weddings",
				"Villas for Corporates",
				"Villas for Get-togethers",
				"Villas for Large Groups",
				"Couple Friendly Villas",
				"Senior Citizen Villas",
				"Kid Friendly Villas",
				"Pet Friendly Villas",
				"Guest FAQs",
			],
			slugs: [
				"/collection/top-villas-for-house-parties",
				"/collection/fairytale-wedding-villas",
				"/collection/corporate-getaway",
				"/collection/book-large-villas-big-group-bungalow-rent",
				"/villas/villas-near-me?checkin=&checkout=&guests=16&rooms=8&lat=19.861&lon=75.3929&amenities=&events=&radius=any&more=",
				"/collection/top-couple-friendly-villas-for-romantic-holiday",
				"/villas/villas-near-me?checkin=&checkout=&guests=1&adults=0&children=0&infants=0&pets=0&rooms=1&lat=19.0748&lon=72.8856&amenities=&events=&pocket=&same_day=&club=&signature=&breakfast=&radius=1&more=31",
				"/collection/children-friendly-holiday-villas",
				"/collection/pet-friendly-villas",
				"/guest-faq",
			],
		},
	];
	return (
		<div className="footer-mob-wrapper">
			<div className="footer-mob-container">
				<div className="footer-mob-content">
					<div className="footer-mob-brand">
						<div className="footer-mob-brand-left">
							<div className="footer-mob-brand-logo">
								<SvgBrand />
							</div>
							<p>
								© {new Date().getFullYear()} SaffronStays. All rights reserved
							</p>
						</div>
						<div className="footer-mob-brand-right">
							<div className="footer-mob-socials">
								<a
									aria-label="Fb"
									href="https://www.facebook.com/saffronstays"
									target="_blank"
									rel="noreferrer"
									onClick={() => {
										clevertap.event.push("social_media_links", {
											timestamp: new Date(),
											type: "Facebook",
										});
									}}
								>
									<SvgFooterFacebook />
								</a>
								<a
									aria-label="Twitter"
									href="https://twitter.com/saffronstays"
									target="_blank"
									rel="noreferrer"
									onClick={() => {
										clevertap.event.push("social_media_links", {
											timestamp: new Date(),
											type: "Twitter",
										});
									}}
								>
									<SvgFooterTwitter />
								</a>
								<a
									aria-label="Linkedin"
									href="https://www.linkedin.com/company/saffronstays"
									target="_blank"
									rel="noreferrer"
									onClick={() => {
										clevertap.event.push("social_media_links", {
											timestamp: new Date(),
											type: "Linkedin",
										});
									}}
								>
									<SvgFooterLinkdin />
								</a>
								<a
									aria-label="Instagram"
									href="https://www.instagram.com/saffronstays/"
									target="_blank"
									rel="noreferrer"
									onClick={() => {
										clevertap.event.push("social_media_links", {
											timestamp: new Date(),
											type: "Instagram",
										});
									}}
								>
									<SvgFooterInsta />
								</a>
								<a
									aria-label="Youtube"
									href="https://www.youtube.com/channel/UC0lvPYqLKtJ_GkoCG-YPY0A"
									target="_blank"
									rel="noreferrer"
									onClick={() => {
										clevertap.event.push("social_media_links", {
											timestamp: new Date(),
											type: "Youtube",
										});
									}}
								>
									<SvgFooterYoutube />
								</a>
								<a
									aria-label="Pinterest"
									href="https://pin.it/3o3iGHe"
									target="_blank"
									rel="noreferrer"
									style={{ fontSize: "19px", color: "white" }}
									onClick={() => {
										clevertap.event.push("social_media_links", {
											timestamp: new Date(),
											type: "Pinterest",
										});
									}}
								>
									<BiLogoPinterestAlt />
								</a>
							</div>
						</div>
					</div>
					<div className="footer-mob-menu">
						<div className="footer-mob-menu-col1">
							{menuColumns?.slice(0, 3)?.map((ele) => {
								return (
									<div className="footer-mob-column">
										<p>{ele?.column}</p>
										<div className="footer-mob-sub-column">
											{ele?.links.map((elem,index) => {
												return (
													<a href={ele?.slugs[index]}>
														<p>{elem}</p>
													</a>
												);
											})}
										</div>
									</div>
								);
							})}
						</div>
						<div className="footer-mob-menu-col1">
							{menuColumns?.slice(3, 4)?.map((ele) => {
								return (
									<div className="footer-mob-column">
										<p>{ele?.column}</p>
										<div className="footer-mob-sub-column">
											{ele?.links.map((elem,index) => {
												return (
													<a href={ele?.slugs[index]}>
														<p>{elem}</p>
													</a>
												);
											})}
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FooterMobile;
